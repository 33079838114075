import { useEffect, useState } from 'react';
import { isAuthenticated, hasExternalProviderAuthSession, setExternalProviderAuthSessionId } from '../../utils/auth';
import { handleRequest } from '../../utils/request';
import Button from '../../Component/Button';
import Modal from '../../Component/Modal';
import Loading from '../../Component/Loading';
import ConfirmPurchaseModalContent from './ConfirmPurchaseModalContent';
import ConfirmVRPurchaseModalContent from './ConfirmVRPurchaseModalContent';
import SignUpModalContent from './SignUpModalContent';
import config from '../../config/config';

import GooglePlay from './googleplay-icon.png';
import AppleStore from './applestore-icon.png';
import MFP from './mfp-with-bg.png';
import VR from './vr-with-bg.png';

import type { ReactElement } from 'react';

export interface ISubscriptionProduct {
	id: string;
	name: string;
	description: string;
	price: number;
	frequency: string;
	userSubscription?: ISubscription;
}

interface ISubscription {
	id: string;
	title: string;
	type: string;
	dateTimeStart: Date;
	dateTimeEnd: Date;
	hasMFP: boolean;
	hasTherapy: boolean;
	hasVR: boolean;
	status: `PENDING` | `LIVE`;
}

interface IPurchaseOptions {
	vrEmail?: string;
}

interface IPurchaseParams {
	productId: string;
	options: IPurchaseOptions;
}

interface ICardProps {
	product?: ISubscriptionProduct;
	onModalOpen: (open: boolean) => void;
	enablePurchase: boolean;
}

interface IBadgeProps {
	text: string;
}

interface IProductsProps {
	enablePurchase: boolean;
}

const getRenewalDate = (subscription: ISubscription): Date => {
	const renewalDate = new Date(subscription.dateTimeEnd);
	return renewalDate;
};

const PurchasedBadge = (props: IBadgeProps): ReactElement => (
	<span
		className={`
            bg-blue-900 dark:bg-blue-800 
			text-gray-200 dark:text-gray-200 text-xs font-medium text-center
            mr-2 px-2.5 py-2 
            rounded-md
            border border-yellow-300
        `}
	>
		{props.text}
	</span>
);

interface IConnectWithProviderResponse {
	sessionId: string;
	url: string;
}

const getConnectionWithProviderParams = async (provider: string): Promise<IConnectWithProviderResponse | null> => {
	const [error, response] = await handleRequest({
		route: `external-provider-connect-url`,
		query: { provider },
	});
	if (error || !response) {
		return null;
	}
	return { sessionId: response.data.sessionId, url: response.data.url };
};

const MFPCard = (props: ICardProps): ReactElement => {
	const { product, enablePurchase, onModalOpen } = props;
	const [loading, setLoading] = useState(false);

	const setConnection = async (): Promise<void> => {
		setLoading(true);
		const connectionParams = await getConnectionWithProviderParams(`health-key`);
		if (!connectionParams) {
			return;
		}
		setExternalProviderAuthSessionId(connectionParams.sessionId);
		setLoading(false);
		window.location.href = connectionParams.url;
	};

	if (!product) return <></>;

	return (
		<div className="max-w-lg p-6 bg-website-light-teal border border-gray-200 rounded-lg shadow dark:border-gray-700 mb-4">
			<div className="flex flex-row flex-wrap mb-4">
				<div className="flex justify-start w-2/3">
					<h5 className="mb-2 text-2xl font-bold tracking-tight text-primary dark:text-primary">{product.name}</h5>
				</div>
				{product.userSubscription && [`LIVE`, `PENDING`].includes(product.userSubscription.status) && (
					<div className="flex justify-end items-center w-1/3">
						<PurchasedBadge text={product.userSubscription.status === `LIVE` ? `Purchased` : `Pending Payment`} />
					</div>
				)}
			</div>
			{!product.userSubscription && (
				<div className="flex flex-wrap justify-center mb-4">
					<span className="mb-4 font-normal text-website-dark-blue dark:text-website-dark-blue mb-1">
						{`Offers a unique way to build mental fitness and prevent burnout with various features, such as yoga videos, guided meditations and mood tracking`}
					</span>
				</div>
			)}
			{product.userSubscription?.status === `LIVE` && (
				<div className="flex flex-wrap justify-center mb-4">
					<span className="text-base font-medium text-gwebsite-dark-blue dark:text-website-dark-blue">
						{`Thanks for purchasing this service`}
					</span>
				</div>
			)}
			<div>
				<img src={MFP} className="mx-auto rounded-full" />
			</div>
			{!product.userSubscription && (
				<div className="flex flex-row justify-between mt-6">
					<div className="flex flex-col">
						<span className="text-xl font-bold text-website-dark-blue dark:text-website-dark-blue mb-1">
							{`£${product.price}`}
						</span>
						<span className="text-xl font-bold text-website-dark-blue dark:text-website-dark-blue">
							{`every ${product.frequency}`}
						</span>
					</div>
					<div className="flex justify-end">
						{!isAuthenticated() && !hasExternalProviderAuthSession() && (
							<div>
								<button
									className={`
									group
									flex justify-between
									relative
									w-full
									mt-2
									py-2 px-4
									border border-transparent rounded-md
									text-white text-sm
									font-medium
									bg-blue-700 hover:bg-blue-800
									focus:outline-none
									focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
								`}
									disabled={loading}
									onClick={(): void => {
										void setConnection();
									}}
								>
									<div>
										<img
											className="w-8 h-8"
											alt="logo"
											src="https://app.healthkey.health/static/media/logo_no_text.f8bccc28a43a9edf9f60.png"
										/>
									</div>
									<div className="py-1 px-4">
										<span>{loading ? `Please wait...` : `Sign In with HealthKey`}</span>
									</div>
								</button>
							</div>
						)}
						{(isAuthenticated() || hasExternalProviderAuthSession()) && enablePurchase && (
							<div className="flex">
								<Button
									onClick={(): void => {
										onModalOpen(true);
									}}
									variant="primary"
									className={`
										text-white 
										bg-primary hover:bg-blue-800
										focus:ring-4 focus:outline-none focus:ring-blue-300 
										font-medium rounded-full text-lg text-center
										px-7 py-2.5 
										dark:bg-primary dark:hover:bg-website-dark-blue dark:focus:ring-website-dark-blue
									`}
									text={`Select`}
								/>
							</div>
						)}
					</div>
				</div>
			)}
			{product.userSubscription?.status === `LIVE` && (
				<div className="flex flex-col flex-wrap mt-6">
					<span className="text-sm font-medium text-website-dark-blue dark:text-website-dark-blue">
						{`To access this service, please to download our mobile app and sign in using your InsideOut credentials.`}
					</span>
					<div className="flex flex-wrap justify-start mt-3 w-full">
						<a href="https://insideoutibhid.app.link/healthkey">
							<img src={GooglePlay} className={`mr-4 mb-3`} />
						</a>
						<a href="https://insideoutibhid.app.link/healthkey">
							<img src={AppleStore} />
						</a>
					</div>
					<div className="mt-2 flex flex-row flex-wrap">
						<span className="text-md font-bold text-website-dark-blue dark:text-website-dark-blue">
							{`Renewal Date:`} &nbsp;
						</span>
						<span className="text-md font-medium text-website-dark-blue dark:text-website-dark-blue">
							{`${getRenewalDate(product.userSubscription).toDateString()}`}
						</span>
					</div>
				</div>
			)}
			{product.userSubscription?.status === `PENDING` && (
				<div className="flex flex-wrap mt-6 p-6 justify-center">
					<span className="font-medium text-lg text-website-dark-blue dark:text-website-dark-blue">{`Please visit`} &nbsp;</span>
					<a className={`font-medium text-lg text-blue-900 decoration-solid underline`} href={config.HEALTH_KEY_URL}>
						{`HealthKey web portal`}
					</a>
					<span className=" font-medium text-lg text-website-dark-blue">&nbsp;{`to confirm the purchase`}</span>
				</div>
			)}
		</div>
	);
};

const VRCard = (props: ICardProps): ReactElement => {
	const { product, enablePurchase, onModalOpen } = props;
	const [loading, setLoading] = useState(false);

	const setConnection = async (): Promise<void> => {
		setLoading(true);
		const connectionParams = await getConnectionWithProviderParams(`health-key`);
		if (!connectionParams) {
			return;
		}
		setExternalProviderAuthSessionId(connectionParams.sessionId);
		setLoading(false);
		window.location.href = connectionParams.url;
	};

	if (!product) return <></>;
	return (
		<div className="max-w-lg p-6 bg-website-light-teal border border-gray-200 rounded-lg shadow dark:border-gray-700 mb-4">
			<div className="flex flex-row flex-wrap mb-4">
				<div className="flex justify-start w-2/3">
					<h5 className="mb-2 text-2xl font-bold tracking-tight text-primary dark:text-primary">{product.name}</h5>
				</div>
				{product.userSubscription && [`LIVE`, `PENDING`].includes(product.userSubscription.status) && (
					<div className="flex justify-end items-center w-1/3">
						<PurchasedBadge text={product.userSubscription.status === `LIVE` ? `Purchased` : `Pending Payment`} />
					</div>
				)}
			</div>
			{!product.userSubscription && (
				<div className="flex flex-wrap justify-center mb-4">
					<span className="mb-4 font-normal text-website-dark-blue dark:text-website-dark-blue mb-1">
						{`A wide range of guided meditations provide you with immersive experiences that help reduce stress and anxiety, improve focus, and promote relaxation`}
					</span>
				</div>
			)}
			{product.userSubscription?.status === `LIVE` && (
				<div className="flex flex-wrap justify-center mb-4">
					<span className="text-base font-medium text-gwebsite-dark-blue dark:text-website-dark-blue">
						{`Thanks for purchasing this service`}
					</span>
				</div>
			)}
			<div>
				<img src={VR} className="mx-auto rounded-full" />
			</div>
			{!product.userSubscription && (
				<div className="flex flex-row justify-between mt-6">
					<div className="flex flex-col">
						<span className="text-xl font-bold text-website-dark-blue dark:text-website-dark-blue mb-1">{`£${product.price}`}</span>
						<span className="text-xl font-bold text-website-dark-blue dark:text-website-dark-blue">{`every ${product.frequency}`}</span>
					</div>
					<div className="flex justify-end">
						{!isAuthenticated() && !hasExternalProviderAuthSession() && (
							<div>
								<button
									className={`
								group
								flex justify-between
								relative
								w-full
								mt-2
								py-2 px-4
								border border-transparent rounded-md
								text-white text-sm
								font-medium
								bg-blue-700 hover:bg-blue-800
								focus:outline-none
								focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
							`}
									disabled={loading}
									onClick={(): void => {
										void setConnection();
									}}
								>
									<div>
										<img
											className="w-8 h-8"
											alt="logo"
											src="https://app.sandbox.healthkey.health/static/media/logo_no_text.f8bccc28a43a9edf9f60.png"
										/>
									</div>
									<div className="py-1 px-4">
										<span>{loading ? `Please wait...` : `Sign In with HealthKey`}</span>
									</div>
								</button>
							</div>
						)}
						{(isAuthenticated() || hasExternalProviderAuthSession()) && enablePurchase && (
							<div className="flex">
								<Button
									onClick={(): void => {
										onModalOpen(true);
									}}
									variant="primary"
									className={`
									text-white 
									bg-primary hover:bg-blue-800
									focus:ring-4 focus:outline-none focus:ring-blue-300 
									font-medium rounded-full text-lg text-center
									px-7 py-2.5 
									dark:bg-primary dark:hover:bg-website-dark-blue dark:focus:ring-website-dark-blue
								`}
									text={`Select`}
								/>
							</div>
						)}
					</div>
				</div>
			)}
			{product.userSubscription?.status === `LIVE` && (
				<div className="flex flex-col mt-6">
					<span className="text-sm font-medium text-gwebsite-dark-blue dark:text-website-dark-blue mb-3">
						{`Thanks for purchasing this service. We will send you an email with instructions on how to access it.`}
					</span>
					<div>
						<span className="text-md font-bold text-website-dark-blue dark:text-website-dark-blue">
							{`Renewal Date:`} &nbsp;
						</span>
						<span className="text-md font-medium text-website-dark-blue dark:text-website-dark-blue">
							{`${getRenewalDate(product.userSubscription).toDateString()}`}
						</span>
					</div>
				</div>
			)}
			{product.userSubscription?.status === `PENDING` && (
				<div className="flex flex-wrap mt-6 p-6 justify-center">
					<span className="font-medium text-lg text-website-dark-blue">{`Please visit`} &nbsp;</span>
					<a className={`font-medium text-lg text-blue-900 decoration-solid underline`} href={config.HEALTH_KEY_URL}>
						{`HealthKey web portal`}
					</a>
					<span className=" font-medium text-lg text-website-dark-blue">&nbsp;{`to confirm the purchase`}</span>
				</div>
			)}
		</div>
	);
};

const Products = (props: IProductsProps): ReactElement => {
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState<ISubscriptionProduct[]>([]);
	const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
	const [confirmPurchaseMFPModalOpen, setConfirmPurchaseMFPModalOpen] = useState(false);
	const [confirmPurchaseVRModalOpen, setConfirmPurchaseVRModalOpen] = useState(false);
	const [purchaseError, setPurchaseError] = useState(``);
	const [showSignUp, setShowSignUp] = useState(false);
	const [purchaseParams, setPurchaseParams] = useState<IPurchaseParams>();

	const sendPurchaseSubscriptionRequest = async (
		productId: string,
		options: IPurchaseOptions,
	): Promise<ISubscription | null> => {
		const [error, reqResult] = await handleRequest({
			route: `purchase-subscription`,
			data: { product: productId, provider: `health-key`, options },
		});
		if (error || reqResult?.status !== 200) {
			return null;
		}
		const item = reqResult.data.subscription;
		return {
			id: item.subscriptionId,
			title: item.subscriptionTitle,
			type: item.subscriptionType,
			hasTherapy: item.featureTherapy,
			hasMFP: item.featureToolkit,
			hasVR: item.featureVR,
			dateTimeStart: new Date(item.startDate),
			dateTimeEnd: new Date(item.endDate),
			status: item.status,
		};
	};

	const purchaseSubscription = async (purchaseItems: IPurchaseParams): Promise<void> => {
		const { productId, options } = purchaseItems;
		setLoading(true);
		setPurchaseError(``);
		const sub = await sendPurchaseSubscriptionRequest(productId, options);
		if (!sub) {
			setPurchaseError(`Something went wrong, please try again`);
			setLoading(false);
		} else {
			setSubscriptions([...subscriptions.concat(sub)]);
			setLoading(false);
			window.location.href = config.HEALTH_KEY_URL ?? `#`;
		}
	};

	const getUserSubscriptions = async (): Promise<ISubscription[]> => {
		const [error, reqResult] = await handleRequest({
			route: `get-subscriptions`,
		});
		if (error || reqResult?.status !== 200) {
			return [];
		}
		const userSubscriptions = reqResult.data.map((item: Record<string, any>) => ({
			id: item.subscriptionId,
			title: item.subscriptionTitle,
			type: item.subscriptionType,
			hasTherapy: item.featureTherapy,
			hasMFP: item.featureToolkit,
			hasVR: item.featureVR,
			dateTimeStart: new Date(item.startDate),
			dateTimeEnd: new Date(item.endDate),
			status: item.status,
		}));
		return userSubscriptions as ISubscription[];
	};

	const getProducts = async (): Promise<ISubscriptionProduct[]> => {
		const [error, reqResult] = await handleRequest({
			route: `get-products`,
		});
		if (error || reqResult?.status !== 200) {
			return [];
		}
		return reqResult.data.products.map((entry: Record<string, any>) => ({
			id: entry.consumerProductId,
			name: entry.name,
			description: entry.description,
			price: entry.price,
			frequency: `${entry.frequencyMonths} month(s)`,
		})) as ISubscriptionProduct[];
	};

	const doesSubscriptionHasProduct = (subscription: ISubscription, productId: string): boolean => {
		switch (productId) {
			case `b4fcae78-a57b-41a9-a097-740d1b53dcdc`:
				return subscription.hasVR;
			case `5121af79-d2af-44dd-985a-b0e1ebc5e5e1`:
				return subscription.hasMFP;
			case `cef93f76-bdc7-4539-96c9-db13e2cf53d3`:
				return subscription.hasTherapy;
			default:
				return false;
		}
	};

	const updateProductsState = (): void => {
		for (const appProduct of products) {
			const activeSub = subscriptions.find(sub => {
				const hasProduct = doesSubscriptionHasProduct(sub, appProduct.id);
				return hasProduct && [`LIVE`, `PENDING`].includes(sub.status) && sub.dateTimeEnd > new Date();
			});
			appProduct.userSubscription = activeSub;
		}
		setProducts([...products]);
	};

	const PurchaseErrorMessage = (): ReactElement => (
		<div
			className={`
				flex items-center justify-center
				rounded
				w-full
				p-2
				mb-3
				bg-red-200
				opacity-90`}
		>
			<p className="font-medium">Something went wrong. Please try again.</p>
		</div>
	);

	useEffect(() => {
		const load = async (): Promise<void> => {
			setLoading(true);
			const appProducts = await getProducts();
			setProducts(appProducts);
			if (isAuthenticated()) {
				const subs = await getUserSubscriptions();
				setSubscriptions(subs);
				updateProductsState();
			}
			setLoading(false);
		};
		void load();
	}, []);

	useEffect(() => {
		updateProductsState();
	}, [subscriptions]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div
			className={`
            	relative 
            	mx-auto w-full
            	py-8 px-4
            	font-extralight
            	text-sm 
            	flex flex-row justify-around flex-wrap
			`}
		>
			{purchaseError && <PurchaseErrorMessage />}
			<MFPCard
				product={products.find(product => product.id === `5121af79-d2af-44dd-985a-b0e1ebc5e5e1`)}
				onModalOpen={(open: boolean): void => {
					setConfirmPurchaseMFPModalOpen(open);
				}}
				enablePurchase={props.enablePurchase}
			/>
			<VRCard
				product={products.find(product => product.id === `b4fcae78-a57b-41a9-a097-740d1b53dcdc`)}
				onModalOpen={(open: boolean): void => {
					setConfirmPurchaseVRModalOpen(open);
				}}
				enablePurchase={props.enablePurchase}
			/>

			{confirmPurchaseMFPModalOpen && (
				<Modal title="Purchase subscription" onCloseClick={(): void => setConfirmPurchaseMFPModalOpen(false)}>
					<ConfirmPurchaseModalContent
						product={products.find(product => product.id === `5121af79-d2af-44dd-985a-b0e1ebc5e5e1`)}
						onPurchaseCallback={(productId: string): void => {
							setPurchaseParams({ productId, options: {} });
							setConfirmPurchaseMFPModalOpen(false);
							if (!isAuthenticated()) {
								setShowSignUp(true);
							} else {
								void purchaseSubscription({ productId, options: {} });
							}
						}}
					/>
				</Modal>
			)}
			{confirmPurchaseVRModalOpen && (
				<Modal title="Purchase subscription" onCloseClick={(): void => setConfirmPurchaseVRModalOpen(false)}>
					<ConfirmVRPurchaseModalContent
						product={products.find(product => product.id === `b4fcae78-a57b-41a9-a097-740d1b53dcdc`)}
						onPurchaseCallback={(productId: string, email?: string): void => {
							setPurchaseParams({ productId, options: { vrEmail: email } });
							setConfirmPurchaseVRModalOpen(false);
							if (!isAuthenticated()) {
								setShowSignUp(true);
							} else {
								void purchaseSubscription({ productId, options: { vrEmail: email } });
							}
						}}
					/>
				</Modal>
			)}
			{showSignUp && (
				<Modal title="SignUp to InsideOut" onCloseClick={(): void => setShowSignUp(false)}>
					<SignUpModalContent
						onSuccessCallback={(): void => {
							setShowSignUp(false);
							purchaseParams && void purchaseSubscription(purchaseParams);
						}}
					/>
				</Modal>
			)}
		</div>
	);
};

export default Products;
