import { useState } from 'react';
import Button from '../../Component/Button';
import { InlineCheckBox } from '../../Component/CheckBox';

import type { ReactElement } from 'react';
import type { ISubscriptionProduct } from './Products';

interface ConfirmVRPurchaseModalContentProps {
	product?: ISubscriptionProduct;
	onPurchaseCallback: (productId: string, email?: string) => void;
}

const ConfirmVRPurchaseModalContent = (props: ConfirmVRPurchaseModalContentProps): ReactElement => {
	const { product } = props;
	const [loading, setLoading] = useState(false);
	const [vrEmail, setVREmail] = useState(``);
	const [useAccountEmail, setUseAccountEmail] = useState(false);

	const canSubmit = useAccountEmail || vrEmail;

	return product ? (
		<>
			<div className="flex flex-wrap">
				<span className="text-lg font-medium">{product.name}</span>
			</div>
			<hr className="mt-2 mb-2" />
			<div className="flex flex-wrap mb-3">
				<span className="text-sm">
					In order to purchase we will ask you to create an InsideOut account, if you don't have one already, in order
					to use the Mobile and VR App.
				</span>
			</div>
			<div className="flex flex-wrap mb-3">
				<span className="text-sm">
					Make sure you have a VR headset Meta Quest 2 before you purchase this subscription
				</span>
			</div>
			<div className="flex flex-col">
				<span className="text-sm mb-1">If your headset is under a different email account, please add it bellow:</span>
				<input
					id="email-address"
					name="email"
					type="email"
					autoComplete="email"
					className={`
                        block
                        relative
                        w-full
                        px-2 py-3
                        border border-gray-300 rounded-t-md focus:border-gray-500
                        placeholder-gray-500
                        ${useAccountEmail ? `text-gray-200 cursor-not-allowed` : `text-gray-900`}
                        sm:text-sm
                        focus:outline-none
                        focus:ring-gray-500
                        focus:z-10
                        appearance-none
                    `}
					disabled={useAccountEmail}
					placeholder="Email address"
					value={vrEmail}
					onChange={(event): void => setVREmail(event.target.value)}
				/>
				<InlineCheckBox
					className="mt-2 text-sm"
					id={`use-account-email-${product.id}`}
					label={`Use your HealthKey email`}
					checked={useAccountEmail}
					onChange={(e): void => {
						setUseAccountEmail(e.target.checked);
						if (e.target.checked) {
							setVREmail(``);
						}
					}}
					highlight={false}
				/>
			</div>
			<hr className="mt-3 mb-3" />
			<div className="flex flex-wrap">
				<span className="text-md font-normal">{`Price: ${product.price} every ${product.frequency}`}</span>
			</div>
			<hr className="mt-3 mb-3" />
			<Button
				className={`
                    mb-1 bg-primary
					rounded-full
                    text-gray-100 font-bold
					px-2 py-2
                    dark:hover:bg-website-dark-blue dark:focus:ring-green-400
                `}
				variant="primary"
				loading={loading}
				onClick={(): void => {
					setLoading(true);
					props.onPurchaseCallback(product.id, vrEmail);
				}}
				disabled={loading || !canSubmit}
				text="Purchase With HealthKey"
			/>
		</>
	) : (
		<>
			<span>{`No product selected. Please go back and select a product`}</span>
		</>
	);
};

export default ConfirmVRPurchaseModalContent;
