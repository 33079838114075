import { useState } from 'react';
import Button from '../../Component/Button';

import type { ReactElement } from 'react';
import type { ISubscriptionProduct } from './Products';

interface ConfirmPurchaseMFPModalContentProps {
	product?: ISubscriptionProduct;
	onPurchaseCallback: (productId: string) => void;
}

const ConfirmPurchaseModalContent = (props: ConfirmPurchaseMFPModalContentProps): ReactElement => {
	const { product } = props;
	const [loading, setLoading] = useState(false);

	return product ? (
		<>
			<div className="flex flex-wrap">
				<span className="text-lg font-medium">{product.name}</span>
			</div>
			<hr className="mt-2 mb-2" />
			<div className="flex flex-wrap mb-3">
				<span className="text-sm">
					In order to purchase we will ask you to create an InsideOut account, if you don't have one already, in order
					to use the Mobile App.
				</span>
			</div>
			<div className="mt-2">
				<span className="text-sm font-normal">{`Price: ${product.price} every ${product.frequency}`}</span>
			</div>
			<hr className="mt-3 mb-3" />
			<Button
				className={`
                    mb-1 bg-primary 
                    text-gray-100 font-bold
					rounded-full
					px-2 py-2
                    dark:hover:bg-website-dark-blue dark:focus:ring-green-400
                `}
				variant="primary"
				loading={loading}
				onClick={(): void => {
					setLoading(true);
					props.onPurchaseCallback(product.id);
				}}
				disabled={loading}
				text="Purchase With HealthKey"
			/>
		</>
	) : (
		<>
			<span>{`No product selected. Please go back and select a product`}</span>
		</>
	);
};

export default ConfirmPurchaseModalContent;
